import classNames from "classnames";
import { Link } from "gatsby";
import React, { ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../state/reducers/authReducer";
import { RootState } from "../state/store";
import { fetchBaseOptions } from "../utils/fetch";
import Navbar from "./Navbar";
import Notification from "./Notification";
import SEO from "./SEO";

interface ILayoutProps {
  children: ReactNode;
  seo?: ISEO;
  className?: string;
  hasDecoration?: boolean;
}

const Layout = ({ children, seo, className, hasDecoration }: ILayoutProps) => {
  const messages = useSelector((state: RootState) => state.app.messages);
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  const notifications = messages.map(m => <Notification key={m.dateString} message={m} />);
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();
  useEffect(() => {
    !authenticated &&
      fetch("/api/login", {
        ...fetchBaseOptions,
        method: "GET",
      })
        .then(async res => {
          if (res.ok) {
            dispatch(loginAction());
          }
        })
        .catch(err => console.error(err));
  }, []);

  return (
    <>
      <SEO {...seo} />
      <header className="sticky top-0 w-full bg-white border-b shadow-sm">
        <Navbar />
      </header>
      <main
        className={classNames(
          "container pt-8 mx-auto",
          className,
          hasDecoration ? "-mb-4" : "pb-12",
        )}
      >
        {children}
      </main>
      <footer
        className={classNames(
          "flex flex-col items-center justify-center py-4 mt-auto",
          hasDecoration && "bg-[#272784]",
        )}
      >
        <div className="mb-2 space-x-4">
          <Link to="/privacy" className={hasDecoration && "text-blue-300 hover:text-blue-200"}>
            Privacy policy
          </Link>
          <Link to="/cookies" className={hasDecoration && "text-blue-300 hover:text-blue-200"}>
            Cookies policy
          </Link>
        </div>
        <p className={hasDecoration && "text-blue-300"}>© {currentYear}</p>
      </footer>
      <div className="fixed top-0 right-0 m-4 space-y-2">{notifications}</div>
    </>
  );
};

export const FormLayout = (props: ILayoutProps) => (
  <Layout seo={props.seo}>
    <div className="max-w-md mx-auto">
      <h1 className="mb-4 text-2xl font-semibold">{props.seo.title}</h1>
      {props.children}
    </div>
  </Layout>
);

interface ISidebarLayoutProps extends ILayoutProps {
  sidebar: JSX.Element;
}

export const SidebarLayout = (props: ISidebarLayoutProps) => (
  <Layout seo={props.seo} className={classNames("grid grid-cols-12 gap-8", props.className)}>
    <div className="col-span-4">{props.sidebar}</div>
    <div className="col-span-8">
      <h1 className="mb-4 text-2xl font-semibold">{props.seo.title}</h1>
      {props.children}
    </div>
  </Layout>
);

export default Layout;
