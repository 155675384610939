import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import React from "react";
import { useDispatch } from "react-redux";
import { removeMessage } from "../state/reducers/appReducer";
import { iconProps } from "../styles/settings";

const styleFromStatus = (status: number) => {
  if (status < 200) {
    return "bg-blue-50 text-blue-700";
  } else if (status < 300) {
    return "bg-green-50 text-green-700";
  } else if (status < 500) {
    return "bg-yellow-50 text-yellow-700";
  } else {
    return "bg-red-50 text-red-700";
  }
};

const NotificationIcon = ({ status }: { status: number }) => {
  let Icon: typeof InformationCircleIcon;
  if (status < 200) {
    Icon = InformationCircleIcon;
  } else if (status < 300) {
    Icon = CheckCircleIcon;
  } else if (status < 500) {
    Icon = ExclamationIcon;
  } else {
    Icon = ExclamationCircleIcon;
  }
  return <Icon className={iconProps.className} />;
};

export default ({ message }: { message: IMessage }) => {
  const classes = classNames(
    styleFromStatus(message.status),
    "rounded pl-2 pr-4 py-2 flex items-center justify-between w-80",
  );
  const dispatch = useDispatch();
  const dismiss = e => {
    e.preventDefault();
    dispatch(removeMessage(message));
  };
  return (
    <div className={classes}>
      <div className="flex items-center space-x-2">
        <NotificationIcon status={message.status} />
        <p>{message.message}</p>
      </div>
      <button onClick={dismiss}>
        <XIcon className="w-4 h-4 opacity-60 hover:opacity-100" />
      </button>
    </div>
  );
};
