import { LogoutIcon } from "@heroicons/react/outline";
import { Form, Formik } from "formik";
import { navigate } from "gatsby";
import React from "react";
import { useDispatch } from "react-redux";
import { addMessage } from "../state/reducers/appReducer";
import { logoutAction } from "../state/reducers/authReducer";
import { iconProps } from "../styles/settings";
import { fetchBaseOptions } from "../utils/fetch";

export default () => {
  const dispatch = useDispatch();
  return (
    <Formik
      initialValues={{}}
      onSubmit={(values, { setSubmitting }) => {
        fetch("/api/logout", {
          ...fetchBaseOptions,
          body: JSON.stringify(values),
        })
          .then(async res => {
            const j: IAPIResponse = await res.json();
            dispatch(addMessage(j));
            if (res.ok) {
              dispatch(logoutAction());
              navigate("/");
            }
          })
          .catch(err => console.error(err))
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {() => (
        <Form className="flex items-center">
          <button type="submit">
            <LogoutIcon aria-label="Logout" className={iconProps.className} />
          </button>
        </Form>
      )}
    </Formik>
  );
};
