import { CogIcon, LoginIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import { iconProps } from "../styles/settings";
import Logout from "./Logout";

export default () => {
  const authenticated = useSelector((state: RootState) => state.auth.authenticated);
  return (
    <nav className="container flex items-center justify-between h-12 mx-auto">
      <div className="flex items-center h-full space-x-4">
        <Link activeClassName="font-semibold" to="/">
          Home
        </Link>
        {authenticated && (
          <Link activeClassName="font-semibold" to="/app/">
            Convert
          </Link>
        )}
      </div>
      <div className="flex items-center h-full space-x-4">
        {authenticated ? (
          <>
            <Link to="/settings">
              <CogIcon aria-label="Settings" className={iconProps.className} />
            </Link>
            <Logout />
          </>
        ) : (
          <Link to="/login">
            <LoginIcon aria-label="Login" className={iconProps.className} />
          </Link>
        )}
      </div>
    </nav>
  );
};
